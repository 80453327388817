import React, {useEffect} from 'react';
import {Layout, Row, Col} from 'antd';
import './index.style.less';
import {AiOutlineMenuFold, AiOutlineMenuUnfold} from 'react-icons/ai';
import PropTypes from 'prop-types';
import AppLanguageSwitcher from '../../AppLanguageSwitcher';
import Breadcrumb from './breadcrumb';
import UserInfo from '../components/UserInfo';
import Notifications from '../components/Notifications';
import {useDispatch, useSelector} from 'react-redux';
import {notificationsService} from 'src/shared/services/notifications';
import MainUtils from 'src/shared/utils/main';
import {
  setIsNotificationsLoading,
  setNotificationsList,
} from 'src/domain/app/redux/notifications/notification-slice';
import {
  getCurrentNotificationsPage,
  getNotificationsList,
} from 'src/domain/app/redux/notifications/notification-selectors';
const {Header} = Layout;

const AppHeader = ({isCollapsed, onToggleSidebar}) => {
  const dispatch = useDispatch();
  const currentNotificationsPage = useSelector(getCurrentNotificationsPage);
  const notificationsList = useSelector(getNotificationsList);

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsNotificationsLoading(true));
        await notificationsService
          .getAllNotifications({
            Page: currentNotificationsPage,
            PageSize: 4,
            Sorts: `-createdDate`,
          })
          .then((response) => {
            const data = response?.Data;
            if (!MainUtils.isEmptyValue(data)) {
              dispatch(
                setNotificationsList({
                  ...notificationsList,
                  totalItems: data?.totalItems,
                  data: notificationsList?.data?.concat(data?.data),
                }),
              );
            }
          });
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setIsNotificationsLoading(false));
      }
    })();
  }, [dispatch, currentNotificationsPage]);

  return (
    <Header className='app-header-mini-sidebar'>
      <Row justify={'space-between'} align={'middle'} className={'fullContent'}>
        <Col span={12}>
          <Row align={'middle'}>
            {React.createElement(
              isCollapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
              {
                className: 'trigger',
                onClick: onToggleSidebar,
              },
            )}
            <Breadcrumb />
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={10} align={'middle'} justify={'end'}>
            <Col>
              <AppLanguageSwitcher />
            </Col>
            <Col>
              <Notifications />
            </Col>
            <Col>
              <UserInfo />
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
};
