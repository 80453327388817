import {PERMISSIONS_KEY} from 'src/shared/constants/AppConst';
import MainUtils from 'src/shared/utils/main';

const hasPermission = (permissionKey: Array<string> | string | undefined) => {
  const permissionsString = localStorage.getItem(PERMISSIONS_KEY);
  const permissions = !MainUtils.isEmptyValue(permissionsString)
    ? JSON.parse(permissionsString ?? '')
    : [];

  return (
    (!MainUtils.isEmptyValue(permissionKey) &&
      !MainUtils.isEmptyValue(permissions) &&
      permissions?.filter((permission: string) =>
        permissionKey?.includes(permission),
      )?.length > 0) ||
    MainUtils.isEmptyValue(permissionKey)
  );
};
export default hasPermission;
