import {Image, Tag} from 'antd';
import {useIntl} from 'react-intl';
import './index.style.less';
import GoogleIconSVG from 'src/assets/icon/logo-google.svg';
import CallIconSVG from 'src/assets/icon/contact/call.svg';
import WebsiteIconSVG from 'src/assets/icon/contact/website.svg';
import EmailIconSVG from 'src/assets/icon/contact/email.svg';
import TwitterIconSVG from 'src/assets/icon/contact/twitter.svg';
import ShareIconSVG from 'src/assets/icon/contact/share.svg';
import StarIconSVG from 'src/assets/icon/star.svg';
import BackIconSVG from 'src/assets/icon/back.svg';
import StreetIconSVG from 'src/assets/icon/street.svg';
import FacebookIconSVG from 'src/assets/icon/facebook.svg';
import TwitterBlueIconSVG from 'src/assets/icon/twitter-blue.svg';
import InstagramColorIconSVG from 'src/assets/icon/instagram-color.svg';
import LinkedInBlueIconSVG from 'src/assets/icon/linkedIn-blue.svg';
import LeftArrowIconSVG from 'src/assets/icon/left-arrow.svg';
import rightArrowIconSVG from 'src/assets/icon/right-arrow.svg';
import sendIconSVG from 'src/assets/icon/send.svg';
import LocationIconSVG from 'src/assets/icon/location.svg';
import CopyLinkIconSVG from 'src/assets/icon/copy-link.svg';
import BottomArrowIconSVG from 'src/assets/icon/bottom-arrow.svg';
import UserDefaultIconSVG from 'src/assets/icon/user-default.svg';
import UserDefaultGrayIconSVG from 'src/assets/icon/user-default-gray.svg';

declare type SafeNumber = number | `${number}`;

interface IconProps {
  alt?: string;
  className?: string;
  width?: SafeNumber | undefined;
  height?: SafeNumber | undefined;
  onClick?: () => void;
}
const GoogleIcon: React.FC<IconProps> = (props) => {
  const {messages} = useIntl();
  return (
    <Image
      src={GoogleIconSVG}
      alt={messages['common.google'].toString()}
      width={17.25}
      height={17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const CallIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={CallIconSVG}
      alt={messages['common.call'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const WebsiteIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={WebsiteIconSVG}
      alt={messages['common.website'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const EmailIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={EmailIconSVG}
      alt={messages['common.email'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const TwitterIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={TwitterIconSVG}
      alt={
        props.alt
          ? messages[props.alt].toString()
          : messages['common.twitter'].toString()
      }
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const ShareIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={ShareIconSVG}
      alt={messages['common.share'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const StarIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={StarIconSVG}
      alt={messages['common.star'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const BackIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={BackIconSVG}
      alt={messages['common.back'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const StreetIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={StreetIconSVG}
      alt={messages['common.street'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const FacebookIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={FacebookIconSVG}
      alt={messages['common.facebook'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const TwitterBlueIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={TwitterBlueIconSVG}
      alt={messages['common.twitter'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const LinkedInBlueIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={LinkedInBlueIconSVG}
      alt={messages['common.likedin'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const InstagramColorIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={InstagramColorIconSVG}
      alt={messages['common.instagram'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const LeftArrowIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={LeftArrowIconSVG}
      alt={messages['common.left'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const RightArrowIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={rightArrowIconSVG}
      alt={messages['common.left'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const SendIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={sendIconSVG}
      alt={messages['common.send'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const LocationIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={LocationIconSVG}
      alt={messages['common.location'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const CopyLinkIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={CopyLinkIconSVG}
      alt={messages['common.copyLink'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const BottomArrowIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={BottomArrowIconSVG}
      alt={messages['common.bottomArrow'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const UserDefaultIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={UserDefaultIconSVG}
      alt={messages['common.user'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const UserDefaultGrayIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={UserDefaultGrayIconSVG}
      alt={messages['common.user'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const NotFound = () => {
  return <Tag className='ant-tag-disable-pointer'></Tag>;
};

export {
  GoogleIcon,
  CallIcon,
  WebsiteIcon,
  EmailIcon,
  TwitterIcon,
  ShareIcon,
  StarIcon,
  BackIcon,
  StreetIcon,
  FacebookIcon,
  TwitterBlueIcon,
  LinkedInBlueIcon,
  InstagramColorIcon,
  LeftArrowIcon,
  RightArrowIcon,
  SendIcon,
  LocationIcon,
  CopyLinkIcon,
  BottomArrowIcon,
  UserDefaultIcon,
  UserDefaultGrayIcon,
  NotFound,
};
